import type { Organization, WithContext } from 'schema-dts';

const data: Record<
  string,
  { email: string; sameAs: string[]; telephone: string }
> = {
  de: {
    email: 'support@gostudent.org',
    sameAs: [
      'https://www.facebook.com/gostudentDE/',
      'https://www.instagram.com/gostudent_de/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@schlaumeier_by_gostudent?',
    ],
    telephone: '+43 670 308 20 68',
  },
  en: {
    email: 'customersupport@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_uk/#',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_uk',
    ],
    telephone: '+44 757 617 13 99',
  },
  el: {
    email: 'gr_support@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_gr/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_gr',
    ],
    telephone: '+30 210 300 56 08',
  },
  es: {
    email: 'soporte@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_es/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_es',
    ],
    telephone: '+34 856 180 115',
  },
  fr: {
    email: 'contact@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_fr/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_fr',
    ],
    telephone: 'N/A',
  },
  it: {
    email: 'assistenzaclienti@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_it/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_it',
    ],
    telephone: '+39 023 056 66 20',
  },
  nl: {
    email: 'nl-support@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_bijles/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_nl',
    ],
    telephone: '+31 970 102 536 86',
  },
  pl: {
    email: 'supportpl@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_pl/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_uk',
    ],
    telephone: '+48 729 086 169',
  },
  tr: {
    email: 'destek@gostudent.org',
    sameAs: [
      'https://www.facebook.com/GoStudentTutoring',
      'https://www.instagram.com/gostudent_tr/',
      'https://www.linkedin.com/company/gostudent-gmbh/',
      'https://www.youtube.com/@GoStudentTutoring',
      'https://www.tiktok.com/@gostudent_tr',
    ],
    telephone: '+902167064720',
  },
};

export const getSchema = (locale: string): WithContext<Organization> => {
  const language = locale.split('-')[0].toLocaleLowerCase() || 'de';

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.gostudent.org/',
    name: 'GoStudent GmbH',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'AT',
      addressLocality: 'Vienna',
      addressRegion: 'Vienna',
      streetAddress: 'Ernst-Melchior-Gasse 22/Stiege 2/EG',
      postalCode: '1020',
    },
    sameAs: data[language].sameAs,
    // email: data[language].email,
    // telephone: data[language].telephone,
    contactPoint: {
      '@type': 'ContactPoint',
      email: data[language].email,
      telephone: data[language].telephone,
    },
    logo: 'https://www.gostudent.org/mpc-assets/logo.png',
    image: 'https://www.gostudent.org/mpc-assets/logo.png',
    vatID: 'ATU70362118',
    foundingDate: '2016',
    founders: [
      {
        '@type': 'Person',
        name: 'Felix Ohswald',
      },
      {
        '@type': 'Person',
        name: 'Gregor Müller',
      },
    ],
  };
};
